enum Paths {
  HOME = '/',
  LOGIN = '/login',
  REGISTER = '/register',
  USER_HOME = '/inicio',
  CREATE_ADVERT = '/adverts/create',
  EDIT_ADVERT = '/adverts/edit',
  MY_ADVERTS = '/my-adverts',
  FORGOT_PASSWORD = '/forgot-password',
  VERIFY_EMAIL = '/verify-email',
  VIEW_ADVERT = '/adverts/:id',
};

export default Paths;