import {
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAuth } from '../../context/auth';
import Paths from '../../paths';

export default function Login() {
  const navigate = useNavigate();
  const auth = useAuth();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [email, setEmail] = useState<string>(searchParams.get('email') || '');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>('');

  useEffect(() => {
    if (auth.user) {
      navigate(Paths.MY_ADVERTS);
    }
  }, [auth.user, navigate]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setError(null);
      setLoading(true);

      const auth = getAuth();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err: any) {
      if (err instanceof FirebaseError) {
        switch (err.code) {
          case 'auth/invalid-email':
          case 'auth/wrong-password':
            setError('Email/senha inválidos');
            break;
          case 'auth/user-not-found':
            setError('Email não cadastrado');
            break;
          default:
            setError('Ocorreu um erro');
            break;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography component="h1" variant="h5">
        Entrar
      </Typography>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          size="small"
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Senha"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          size="small"
        />
        <Grid
          container
          justifyContent="center"
        >
          <Typography
            variant="caption"
            color="error"
          >
            {error}
          </Typography>
        </Grid>
        <LoadingButton
          loading={loading}
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          size="small"
          disabled={!email || !password}
        >
          Entrar
        </LoadingButton>
        <Grid
          container
          direction="column"
        >
          <Grid item>
            <Link to={Paths.FORGOT_PASSWORD}>
              Esqueci minha senha
            </Link>
          </Grid>
          <Grid item>
            <Link to={Paths.REGISTER}>
              {"Não tem uma conta? Cadastre-se"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
