import IAdvert from "../../models/IAdvert";

export enum ActionTypes {
  setIsEditing,
  setAdvert,
  setId,
  setUid,
  setTitle,
  setDescription,
  setCategories,
  setPhone,
  setPrice,
  setDate,
  setFiles,
  setActive,
  setAcceptExchange,
  setState,
  setCity,
  setSold,
  setFilesToAdd,
  setFilesToRemove,
  setAdvertiser,
}

interface IAction {
  type: ActionTypes,
  payload: any,
}

export interface IState extends IAdvert {
  filesToAdd: File[],
  filesToRemove: File[],
  isEditing: boolean,
}

export default function reducer(state: IState, { type, payload }: IAction) {
  switch (type) {
    case ActionTypes.setAdvert: return {
      ...state,
      id: payload.id,
      uid: payload.uid,
      title: payload.title,
      description: payload.description,
      categories: payload.categories,
      phone: payload.phone,
      price: payload.price,
      date: payload.date,
      files: payload.files || [],
      active: payload.active,
      acceptExchange: payload.acceptExchange,
      state: payload.state,
      city: payload.city,
      sold: payload.sold,
      isEditing: true,
      advertiser: payload.advertiser,
    };
    case ActionTypes.setId: return { ...state, id: payload };
    case ActionTypes.setUid: return { ...state, uid: payload };
    case ActionTypes.setTitle: return { ...state, title: payload };
    case ActionTypes.setDescription: return { ...state, description: payload };
    case ActionTypes.setCategories: return { ...state, categories: payload };
    case ActionTypes.setPhone: return { ...state, phone: payload };
    case ActionTypes.setPrice: return { ...state, price: payload };
    case ActionTypes.setDate: return { ...state, date: payload };
    case ActionTypes.setFiles: return { ...state, files: payload };
    case ActionTypes.setIsEditing: return { ...state, isEditing: payload };

    case ActionTypes.setFilesToRemove: return (state.filesToRemove.includes(payload) ?
      state : { ...state, filesToRemove: [...state.filesToRemove, payload] });

    case ActionTypes.setFilesToAdd: return (state.filesToAdd.includes(payload) ?
      state : { ...state, filesToAdd: [...state.filesToAdd, ...payload] });

    case ActionTypes.setActive: return { ...state, active: payload };
    case ActionTypes.setAcceptExchange: return { ...state, acceptExchange: payload };
    case ActionTypes.setState: return { ...state, state: payload };
    case ActionTypes.setCity: return { ...state, city: payload };
    case ActionTypes.setSold: return { ...state, sold: payload };
    case ActionTypes.setAdvertiser: return { ...state, advertiser: payload };
    default:
      throw new Error('Erro no reducer');
  }
}