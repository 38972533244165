import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Typography, Paper, Grid } from '@mui/material';
import { ActionCodeSettings } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import Paths from '../../paths';

export default function VerifyEmail() {
  const {
    user,
    reloadUser,
    confirmEmailVerification,
    sendEmailVerification,
  } = useAuth();
  const navigate = useNavigate();
  const [sendIsloading, setSendIsLoading] = useState<boolean>(false);
  const [confirmIsLoading, setConfirmIsLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (user?.emailVerified) {
      navigate(Paths.HOME);
    }
  }, [user, navigate]);

  const handleSendEmail = async () => {
    try {
      setSendIsLoading(true);
      await sendEmailVerification();
      setEmailSent(true);
    } catch (err: any) {
      if (err instanceof FirebaseError) {
        setError(err.message);
      }
    } finally {
      setSendIsLoading(false);
    }
  };

  const handleConfirm = async () => {
    setConfirmIsLoading(true);
    const currentUser = await reloadUser();

    if (currentUser?.emailVerified) {
      confirmEmailVerification();
    } else {
      setError(`
        Ainda não recebemos a confirmação do seu email.
        Verifique sua caixa de entrada e faça a confirmação clicando no link.
        Caso não tenha recebido um email da nossa equipe,
        clique em 'Enviar email de confirmação'.
      `);
    }

    setConfirmIsLoading(false);
  };

  return (
    <Grid
      container
      justifyContent="center"
    >
      <Paper variant="elevation">
        <Grid
          item
          container
          justifyContent="center"
          spacing={2}
          sx={{
            padding: 2,
          }}
        >
          <Grid item>
            <Typography textAlign="center">
              {`
                Por favor, acesse seu email e clique no link
                para confirmar o cadastro.
              `}
            </Typography>
            <Typography textAlign="center">
              {'Link enviado para '}
              <strong>{user?.email}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
          >
            <LoadingButton
              variant="contained"
              loading={confirmIsLoading}
              onClick={handleConfirm}
              size="small"
            >
              Já confirmei, entrar
            </LoadingButton>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
          >
            <Typography
              variant='caption'
              color="error"
              textAlign="center"
            >
              {error}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
          >
            <Grid item>
              <Typography
                textAlign="center"
                variant="caption"
              >
                Não recebi um email
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
            >
              <LoadingButton
                loading={sendIsloading}
                onClick={handleSendEmail}
                disabled={emailSent}
              >
                {(emailSent && 'Email enviado!') || 'Enviar email de confirmação'}
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}