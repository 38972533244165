import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import {
  Lazy,
  Navigation,
  Pagination,
  Zoom,
} from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/lazy';
import 'swiper/css/zoom';
import './styles.css';

const PhotoDiv = ({ files }) => {
  return (
    <Swiper
      style={{
        "--swiper-navigation-color": "#fff",
        "--swiper-pagination-color": "#fff",
      }}
      zoom={true}
      lazy={true}
      navigation={true}
      pagination={true}
      modules={[Lazy, Navigation, Pagination, Zoom]}
      slidesPerView={1}
      centeredSlides={true}
      preloadImages={false}
    >
      {
        files.map(file => (
          <SwiperSlide>
            <div className="swiper-zoom-container">
              <img
                src={file.url}
                className="swiper-lazy"
                alt="..."
                loading='lazy'
              />
              <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>
  );
};

export default PhotoDiv;