import {
  collection,
  getDocs,
  query,
  where,
  QueryDocumentSnapshot,
  SnapshotOptions,
  doc,
  addDoc,
  deleteDoc,
  setDoc,
  getDoc,
} from 'firebase/firestore';
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
  list,
} from 'firebase/storage';

import { firestore, storage } from '../../firebase';
import IAdvert from '../../models/IAdvert';
import IAdvertFile from '../../models/IAdvertFile';

const collectionName = 'adverts';

const advertConverter = {
  toFirestore: (advert: IAdvert) => advert,
  fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
    const data = snapshot.data(options);
    const advert: IAdvert = {
      id: snapshot.id,
      uid: data.uid,
      title: data.title,
      description: data.description,
      categories: data.categories,
      phone: data.phone,
      price: data.price,
      date: data.date,
      files: data.files,
      active: data.active,
      acceptExchange: data.acceptExchange,
      city: data.city,
      state: data.state,
      sold: data.sold,
      advertiser: data.advertiser,
    };

    return advert;
  },
};

export default {
  findAllAdverts: async () => {
    const q = query(
      collection(firestore, collectionName).withConverter(advertConverter),
      where('active', '==', true),
      where('sold', '==', false),
    );
    const querySnapshot = await getDocs(q);
    const adverts = querySnapshot.docs.map((doc) => {
      const data = doc.data();

      return data;
    });

    return adverts;
  },

  findAdvert: async (id: string) => {
    const docRef = doc(firestore, collectionName, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      const advert: IAdvert = {
        id: docSnap.id,
        uid: data.uid,
        title: data.title,
        description: data.description,
        categories: data.categories,
        phone: data.phone,
        price: data.price,
        date: data.date,
        files: data.files,
        active: data.active,
        acceptExchange: data.acceptExchange,
        city: data.city,
        state: data.state,
        sold: data.sold,
        advertiser: data.advertiser,
      };

      return advert;
    } else {
      throw new Error('No such document!');
    }
  },

  findUserAdverts: async (uid: string) => {
    const q = query(
      collection(firestore, collectionName).withConverter(advertConverter),
      where('uid', '==', uid),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  },

  addAdvert: async (advert: IAdvert) => {
    const col = collection(firestore, collectionName);
    const resp = await addDoc(col, advert);

    return resp;
  },

  uploadFile: async (folderName: string, fileName: string, file: any) => {
    const path = `${folderName}/${fileName}`;
    const storageRef = ref(storage, path);

    return uploadBytes(storageRef, file);
  },

  updateAdvert: (advert: IAdvert) => {
    const advertRef = doc(firestore, collectionName, advert.id);

    return setDoc(advertRef, advert, { merge: true });
  },

  listAllFiles: async (id: string): Promise<IAdvertFile[]> => {
    const listRef = ref(storage, id);
    const allFiles = await listAll(listRef);
    const filteredFiles = allFiles.items
      .filter(({ name }) => name.includes('500x500'));

    const urls = await Promise.all(
      filteredFiles.map((itemRef) => {
        const urlRef = ref(storage, itemRef.fullPath);
        return getDownloadURL(urlRef);
      }),
    );

    const files = filteredFiles
      .map(({ name, fullPath }, i) => {
        const file: IAdvertFile = {
          name,
          fullPath,
          url: urls[i],
        };

        return file;
      });

    return files;
  },

  deleteFile: async (folderName: string, fileName: string) => {
    const path = `${folderName}/${fileName}`;
    const fileRef = ref(storage, path);

    return deleteObject(fileRef);
  },

  deleteFolder: async (folderName: string) => {
    try {
      const fileRef = ref(storage, folderName);
      const listItems = await list(fileRef);
      const promises = listItems.items.map(itemRef => deleteObject(itemRef));

      await Promise.all(promises);
    } catch (error: any) {
      console.error(error.message);
    }
  },

  deleteAdvert: (id: string) => {
    const d = doc(firestore, collectionName, id);

    return deleteDoc(d);
  },
};