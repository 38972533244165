import { Grid, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import AdvertActions from '../../actions/adverts/AdvertActions';
import { AdCard } from '../../components';
import { useAuth } from '../../context/auth';
import { useNotification } from '../../context/notification';
import IAdvert from '../../models/IAdvert';
import AdvertsService from '../../services/adverts';

export default function MyAdverts() {
  const { user } = useAuth();
  const { setNotification } = useNotification();
  const [myAdverts, setMyAdverts] = useState<IAdvert[]>([]);

  useEffect(() => {
    if (!user?.uid) return;

    AdvertsService
      .findUserAdverts(user.uid)
      .then(setMyAdverts)
      .catch((err) => console.error(err.message));
  }, [user]);

  const deleteAdvert = async (id: string) => {
    try {
      await AdvertActions.deleteAdvert(id);

      setMyAdverts(adverts => adverts.filter(adv => adv.id !== id));
      setNotification({
        message: 'Anuncio excluido com sucesso',
        severity: 'success',
      });
    } catch {
      setNotification({
        message: 'Erro ao exluir anúncio',
        severity: 'error',
      });
    }
  }

  return (
    <Grid>
      <Typography
        variant='h6'
        textAlign='center'
      >
        Meus anúncios
      </Typography>
      <Grid
        item
        container
        justifyContent='center'
      >
        {
          myAdverts
            .map((advert: IAdvert) => (
              <AdCard
                key={advert.id}
                advert={advert}
                deleteAdvert={deleteAdvert}
              />
            ))
        }
        {
          myAdverts.length === 0 && (
            <Typography variant='body1'>
              Você não possui anúncios.
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
}
