export const listOfStatus = {
  IDLE: 'idle',
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
};

export const formatDate = (dateInMilis: number) => {
  const date = new Date(dateInMilis);

  return date.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatPhone = (phoneNumber: string) => {
  phoneNumber = phoneNumber.replace(/\D/g,""); //Remove tudo o que não é dígito
  phoneNumber = phoneNumber.replace(/^(\d{2})(\d)/g,"($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
  phoneNumber = phoneNumber.replace(/(\d)(\d{4})$/,"$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos

  return phoneNumber;
};