import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useState, useEffect, useContext } from 'react';

interface Notification {
  message: string,
  severity: AlertColor,
}

interface INotificationContext {
  setNotification: (notification: Notification) => void,
}

export const NotificationContext = React.createContext<INotificationContext>(null!);

export function NotificationProvider({ children }: { children: React.ReactNode }) {
  const [notification, setNotification] = useState<Notification | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setNotification(null);
  };

  useEffect(() => {
    if (notification) {
      setOpen(true);
    }
  }, [notification]);

  let value = {
    setNotification: (n: Notification) => setNotification(n),
  };

  return (
    <NotificationContext.Provider value={value}>
      <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={notification?.severity} sx={{ width: '100%' }}>
          {notification?.message}
        </Alert>
      </Snackbar>
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  return useContext(NotificationContext);
};