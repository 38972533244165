import { CssBaseline } from '@mui/material';
import { NotificationProvider } from './context/notification';
import { AuthProvider } from './context/auth';
import Router from './Router';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

export default function App() {
  return (
    <>
      <CssBaseline />
      <NotificationProvider>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </NotificationProvider>
    </>
  );
};
