import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  TextField,
  Grid,
  Button,
} from '@mui/material';
import { FirebaseError } from 'firebase/app';
import { LoadingButton } from '@mui/lab';
import { useNotification } from '../../context/notification';
import Paths from '../../paths';
import { useAuth } from '../../context/auth';

export default function CreateUser() {
  const {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    updateDisplayName,
  } = useAuth();
  const navigate = useNavigate();
  const { setNotification } = useNotification();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [displayName, setDisplayName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const createDisabled = loading
    || !displayName
    || !email
    || !password
    || !confirmPassword
    || password !== confirmPassword;

  const create = async (event: any) => {
    event.preventDefault();
    try {
      if (createDisabled) return;
      setError(null);
      setLoading(true);

      await createUserWithEmailAndPassword(email, password);
      await sendEmailVerification();
      await updateDisplayName(displayName);

      setNotification({
        message: 'Cadastro efetuado com sucesso',
        severity: 'success',
      });
      navigate(Paths.VERIFY_EMAIL);
    } catch (err: any) {
      if (err instanceof FirebaseError) {
        switch (err.code) {
          case 'auth/invalid-email':
            setError('Formato de email inváido');
            break;
          case 'auth/email-already-in-use':
            setError('Este email já está sendo usado');
            break;
          case 'auth/weak-password':
            setError('A senha deve ter no mínimo 6 caracteres');
            break;
          default:
            setError('Ocorreu um erro');
            break;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid
      container
      direction="row"
      xs={12}
      spacing={2}
      alignItems="center"
      sx={{
        paddingY: 2,
        maxWidth: 500,
      }}
    >
      <Grid item>
        <Typography variant="h6">Cadastro</Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          size="small"
          label="Nome"
          defaultValue="Default Value"
          type="text"
          value={displayName}
          onChange={({ target }) => setDisplayName(target.value)}
          placeholder="João da Silva"
          required
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          size="small"
          fullWidth
          label="Email"
          defaultValue="Default Value"
          type="email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          placeholder="email@gmail.com"
          helperText="Informe um email válido"
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          fullWidth
          label="Senha"
          defaultValue="Default Value"
          type="password"
          value={password}
          onChange={({ target }) => setPassword(target.value)}
          placeholder="******"
          helperText="Mínimo 6 caracteres"
          required
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          size="small"
          fullWidth
          label="Confirme a sua senha"
          defaultValue="Default Value"
          type="password"
          value={confirmPassword}
          onChange={({ target }) => setConfirmPassword(target.value)}
          placeholder="******"
          helperText="Repita a sua senha"
          required
        />
      </Grid>
      <Grid
        container
        justifyContent="center"
        item
        xs={12}
      >
        <Typography
          variant="caption"
          color="error"
        >
          {error}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
      >
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate(Paths.HOME)}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={create}
          loading={loading}
          disabled={createDisabled}
        >
          Cadastrar
        </LoadingButton>
      </Grid>
    </Grid>
  );
}
