import { useState } from 'react';
import {
  Paper,
  Typography,
  TextField,
  Grid,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuth } from '../../context/auth';
import { formatPhone } from '../../utils';
import { useNavigate } from 'react-router-dom';
import Paths  from '../../paths';

export default function UserHome() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [displayName, setDisplayName] = useState(user.displayName ?? '');
  const [emailVerified, setEmailVerified] = useState(user.emailVerified);
  const [phone, setPhone] = useState(user.phoneNumber || '');

  // const updateUserProfile = () => {
  //   try {
  //     UserService.update(user.uid, {
  //       phone,
  //       city,
  //       state,
  //     });
  //     const auth = getAuth();
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // }

  return (
    <>
      <Typography
        variant='h6'
        textAlign='center'
      >
        Meu perfil
      </Typography>
      <Paper
        variant="elevation"
        sx={{ padding: 2 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Nome"
              value={displayName}
              onChange={setDisplayName}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Nome"
              value={user.email}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Email verificado"
              value={(emailVerified && 'Sim') || 'Não'}
              onChange={setEmailVerified}
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              size="small"
              fullWidth
              label="Telefone"
              value={formatPhone(phone?.toString())}
              inputProps={{ maxLength: "15" }}
              onChange={({ target }) => setPhone(formatPhone(target.value))}
              disabled
            />
          </Grid>

          <Grid
            item
            xs={12}
            container
            justifyContent="center"
          >
            <Button
              color="primary"
              onClick={() => navigate(Paths.HOME)}
            >
              voltar para home
            </Button>
            <LoadingButton
              variant="contained"
              color="primary"
              disabled
            >
              Salvar
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
