import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AccountBox as AccountBoxIcon, AddCircle as AddCircleIcon, ExitToApp as ExitToAppIcon, Home as HomeIcon, List as ListIcon, Menu as MenuIcon } from '@mui/icons-material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useAuth } from '../context/auth';
import Paths from '../paths';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, signout } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClose = () => setMenuOpen(false);

  const listOfItems = [
    {
      label: 'Anúncios',
      key: Paths.HOME,
      icon: <HomeIcon />,
      onClick: () => {
        navigate(Paths.HOME);
        handleClose();
      },
    },
    {
      label: 'Anunciar',
      key: Paths.CREATE_ADVERT,
      icon: <AddCircleIcon />,
      onClick: () => {
        navigate(Paths.CREATE_ADVERT);
        handleClose();
      },
    },
    {
      key: Paths.USER_HOME,
      label: 'Meu perfil',
      icon: <AccountBoxIcon />,
      onClick: () => {
        navigate(Paths.USER_HOME);
        handleClose();
      },
    },
    {
      label: 'Meus anúncios',
      key: Paths.MY_ADVERTS,
      icon: <ListIcon />,
      onClick: () => {
        navigate(Paths.MY_ADVERTS);
        handleClose();
      },
    },
    {
      key: 'logout',
      icon: <ExitToAppIcon />,
      label: 'Sair',
      onClick: () => {
        signout();
        handleClose();
      },
    },
  ];

  return (
    <>
      <Drawer
        anchor="left"
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        <List>
          {
            listOfItems
              .map(({ key, icon, label, onClick }) => (
                <ListItem
                  key={key}
                  button
                  onClick={onClick}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              ))
          }
        </List>
      </Drawer>
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              xs="auto"
            >

              <Grid item>
                {
                  user?.uid
                  && user?.emailVerified
                  && (
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={() => setMenuOpen(true)}
                      sx={{ marginRight: 2 }}
                    >
                      <MenuIcon />
                    </IconButton>
                  )
                }
              </Grid>
              <Grid item>
                <Typography
                  variant="h6"
                  component="h6"
                  onClick={() => navigate(Paths.HOME)}
                  noWrap
                  sx={{
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  Brique do Som Automotivo
                </Typography>
              </Grid>

            </Grid>

            <Grid>
              {
                !user
                && location.pathname !== Paths.LOGIN
                && (
                  <Button
                    variant="text"
                    color="secondary"
                    size="medium"
                    onClick={() => navigate(Paths.LOGIN)}
                    sx={{ color: '#FFFFFF' }}
                  >
                    Entrar
                  </Button>
                )
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="md"
        sx={{
          padding: 2,
        }}
      >
        {
          ![
            Paths.LOGIN.toString(),
            Paths.FORGOT_PASSWORD.toString(),
            Paths.REGISTER.toString(),
            Paths.CREATE_ADVERT.toString(),
            Paths.EDIT_ADVERT.toString(),
            Paths.VERIFY_EMAIL.toString(),
          ].includes(location.pathname) && (
            <Fab
              variant="extended"
              size="large"
              color="secondary"
              onClick={() => navigate(Paths.CREATE_ADVERT)}
              sx={{
                position: 'fixed',
                bottom: 24,
                right: 24,
              }}
            >
              <AddIcon />
              Anunciar
            </Fab>
          )
        }
        <Outlet />
      </Container>
    </>
  );
}

export default Layout;
