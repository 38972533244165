import { useEffect, useState } from 'react';
import {
  AdCard,
  CircularProgress,
} from '../../components';
import AdvertService from '../../services/adverts';
import IAdvert from '../../models/IAdvert';
import ERequestStatus from '../../models/ERequestStatus';
import Stack from '@mui/material/Stack';
// import { Grid, IconButton, TextField } from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
import { useNotification } from '../../context/notification';
// import typesense from '../../typesense';

export default function Home() {
  const { setNotification } = useNotification();
  const [search, /* setSearch */] = useState<string>('');
  const [adverts, setAdverts] = useState<Array<IAdvert>>([]);
  const [status, setStatus] = useState<ERequestStatus>(ERequestStatus.IDLE);

  useEffect(() => {
    const getAdverts = async () => {
      try {
        setStatus(ERequestStatus.PENDING);
        const data = await AdvertService.findAllAdverts();
        setAdverts(data);
        setStatus(ERequestStatus.RESOLVED);
      } catch (error) {
        setStatus(ERequestStatus.REJECTED);
        setNotification({
          message: 'Erro ao carregar os anúncios',
          severity: 'error',
        });
      }
    }

    if (!search) {
      getAdverts();
    }
  }, [search, setNotification]);

  // const handleSearch = async () => {
  //   try {
  //     const { hits } = await typesense
  //       .collections('adverts')
  //       .documents()
  //       .search({
  //         q: search,
  //         query_by: 'title,description',
  //       });

  //     if (hits) {
  //       const list = hits.map(({ document }: any) => {
  //         const advert: IAdvert = {
  //           id: document.id,
  //           uid: document.uid,
  //           title: document.title,
  //           description: document.description,
  //           categories: document.categories,
  //           phone: document.phone.toString(),
  //           price: document.price,
  //           date: document.date,
  //           active: document.active,
  //           acceptExchange: document.acceptExchange,
  //           city: {
  //             id: Number(document['city.id']),
  //             label: document['city.label'],
  //           },
  //           state: {
  //             id: document['state.id'],
  //             label: document['state.label'],
  //           },
  //           sold: document.sold,
  //           advertiser: document.advertiser,
  //           files: [],
  //         };

  //         return advert;
  //       });

  //       setAdverts(list);
  //     }
  //   } catch (err: any) {
  //     setNotification({
  //       message: err.message,
  //       severity: 'error',
  //     });
  //   }
  // }

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      {/* <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ maxWidth: 500 }}
      >
        <Grid item xs={10}>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            label="Eu preciso de um..."
            onChange={({ target }: any) => setSearch(target.value)}
            sx={{ marginY: 1 }}
          />
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent="center"
        >
          <IconButton
            onClick={handleSearch}
          >
            <SearchIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid> */}
      {
        status === ERequestStatus.PENDING && (
          <CircularProgress size={30} />
        )
      }
      {
        status === ERequestStatus.RESOLVED
        && adverts
          .map((advert: IAdvert) => (
            <AdCard
              key={advert.id}
              advert={advert}
              deleteAdvert={() => {}}
            />
          ))
      }
    </Stack>
  );
}
