import {
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
  useMatch,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import {
  DeleteIcon,
  EditIcon,
  WhatsAppIcon,
  VisibilityIcon,
} from '../../assets/icons';
import Paths from '../../paths';
import AdvertService from '../../services/adverts';
import IAdvert from '../../models/IAdvert';
import IAdvertFile from '../../models/IAdvertFile';
import PhotoDiv from '../photoDiv';
import {
  formatDate,
  formatPhone,
} from '../../utils';

interface AdCardProps {
  key: string,
  advert: IAdvert,
  deleteAdvert(id: string): void,
}

export default function AdCard({ advert, deleteAdvert }: AdCardProps) {
  const {
    id,
    title,
    description,
    categories,
    phone,
    price,
    date,
    active,
    acceptExchange,
    city,
    state,
    advertiser,
  } = advert;
  const [modalOpened, setModalOpened] = useState(false);
  const [files, setFiles] = useState<IAdvertFile[]>([]);
  let navigate = useNavigate();
  const match = useMatch('adverts/:id');
  const onMyAdverts = useMatch(Paths.MY_ADVERTS);

  useEffect(() => {
    AdvertService.listAllFiles(id)
      .then(setFiles)
      .catch((err) => console.error(err.message));
  }, [id]);

  const handleClose = () => setModalOpened(false);

  return (
    <Card
      sx={{
        marginBottom: 2,
        width: '100%',
        maxWidth: 500,
      }}
    >
      <Grid
        item
        xs={12}
        sx={{ minHeight: 300 }}
      >
        <PhotoDiv files={files} />
      </Grid>
      <CardActions>
        {
          <Grid
            container
            item
            direction="row"
          >
            <Grid
              xs={6}
              item
              container
              alignItems="center"
              justifyContent="flex-start"
            >
              {
                onMyAdverts && (
                  <Typography
                    sx={{ marginLeft: 2 }}
                  >
                    {`Anúncio ${active ? 'ativo' : 'inativo'}`}
                  </Typography>
                )
              }
            </Grid>
            <Grid
              item
              xs={6}
              container
              justifyContent="flex-end"
            >
              {
                !Boolean(match) && (
                  <Tooltip
                    title="Visualizar anúncio"
                    placement="top"
                    arrow
                  >
                    <IconButton
                      color="secondary"
                      aria-label="visualize"
                    >
                      <VisibilityIcon
                        fontSize='large'
                        color='primary'
                        onClick={() => match ? null : navigate(`/adverts/${id}`)}
                      />
                    </IconButton>
                  </Tooltip>
                )
              }
              {
                Boolean(onMyAdverts) && (
                  <>
                    <Tooltip
                      title="Editar anúncio"
                      placement="top"
                      arrow
                    >
                      <IconButton
                        aria-label="edit"
                        onClick={() => navigate(Paths.EDIT_ADVERT, { state: advert })}
                        disabled={files && files.length <= 0}
                      >
                        <EditIcon
                          fontSize='large'
                          color='secondary'
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title="Deletar anúncio"
                      placement="top"
                      arrow
                    >
                      <IconButton
                        color="secondary"
                        aria-label="delete"
                        onClick={() => setModalOpened(true)}
                      >
                        <DeleteIcon
                          fontSize='large'
                          color='error'
                        />
                      </IconButton>
                    </Tooltip>
                  </>
                )
              }
            </Grid>
          </Grid>
        }
      </CardActions>
      <CardContent>
        <Grid
          container
          direction="column"
        >
          <Grid
            container
            item
            direction="column"
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">{title}</Typography>
              <Typography variant="h6" component="h6">{`R$ ${price}`}</Typography>
            </Grid>
            <Divider />
            <Typography
              variant="body2"
              sx={{
                whiteSpace: 'pre-line',
                marginTop: 1,
                marginBottom: 2,
              }}
            >
              {description}
            </Typography>
            <Grid
              container
              justifyContent="flex-end"
            >
              <Tooltip title="Chamar anunciante" placement="top" arrow>
                <IconButton
                  aria-label="edit"
                  disabled={files && files.length <= 0}
                  target='_blank'
                  href={`https://api.whatsapp.com/send?phone=55${phone}&text=Olá,%20meu%20amigo!`}
                >
                  <WhatsAppIcon />
                  <Typography sx={{ marginLeft: 1 }} variant="body1">
                    {`${formatPhone(phone.toString())} | ${advertiser}`}
                  </Typography>
                </IconButton>
              </Tooltip>
            </Grid>
            <Typography
              variant="body2"
            >
              {`*${acceptExchange ? 'Aceita troca' : 'Não aceita troca'}`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            sx={{ marginY: 1 }}
          >
            {
              categories
              && categories.length > 0
              && categories.map(category => (
                <Chip
                  key={category}
                  label={`#${category}`}
                  style={{ margin: 1 }}
                  size="small"
                />
              ))
            }
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="row"
          alignItems="baseline"
          justifyContent="space-between"
        >
          <Typography variant="caption">{`Publicado em: ${formatDate(date)}`}</Typography>
          <Typography variant="caption">{`Localização: ${city.label}, ${state.label}`}</Typography>
        </Grid>
      </CardContent>
      <Dialog
        open={modalOpened}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {
            `Tem certeza que quer excluir este anúncio. \n
            Se você não tem certeza, recomendamos apenas desativá-lo`
          }
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deleteAdvert(id);
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
