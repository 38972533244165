import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateProfile,
  sendEmailVerification,
  ActionCodeSettings,
} from 'firebase/auth';
import IUser from '../../models/IUser';

interface AuthContextType {
  user: IUser | null;
  signin: (email: string, password: string) => void;
  signout: () => void;
  reloadUser: () => Promise<IUser>;
  confirmEmailVerification: () => void,
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<void>;
  updateProfile: (data: { displayName: string }) => Promise<void>;
  sendEmailVerification: () => Promise<void>;
  updateDisplayName: (displayName: string) => Promise<void>;
  sendPasswordResetEmail: (email: string, settings: ActionCodeSettings) => Promise<void>;
}

export const AuthContext = React.createContext<AuthContextType>(null!);
export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = getAuth();
  const [user, setUser] = useState<IUser | null>(null);

  useEffect(() => {
    onAuthStateChanged(auth, (userCredential) => {
      if (userCredential) {
        setUser({
          uid: userCredential.uid,
          displayName: userCredential.displayName,
          email: userCredential.email,
          emailVerified: userCredential.emailVerified,
          phoneNumber: userCredential.phoneNumber,
          photoURL: userCredential.photoURL,
        });
      } else {
        setUser(null);
      }
    });
  }, [auth]);

  let value = {
    user,
    signin: async (email: string, password: string) => signInWithEmailAndPassword(auth, email, password),
    signout: () => signOut(auth),
    createUserWithEmailAndPassword: async (email: string, password: string) => {
      await createUserWithEmailAndPassword(auth, email, password);
    },
    sendPasswordResetEmail: async (email: string, settings: ActionCodeSettings) => {
      await sendPasswordResetEmail(auth, email, settings);
    },
    reloadUser: async () => {
      await auth.currentUser?.reload();
      const { currentUser } = auth;

      return ({
        displayName: currentUser?.displayName,
        email: currentUser?.email,
        phoneNumber: currentUser?.phoneNumber,
        photoURL: currentUser?.photoURL,
        uid: currentUser?.uid,
        emailVerified: currentUser?.emailVerified,
      });
    },
    confirmEmailVerification: () => {
      setUser(usr => ({
        displayName: usr?.displayName,
        email: usr?.email,
        phoneNumber: usr?.phoneNumber,
        photoURL: usr?.photoURL,
        uid: usr?.uid,
        emailVerified: true,
      }));
    },
    updateProfile: async ({ displayName }: { displayName: string }) => {
      if (auth.currentUser) {
        await updateProfile(auth.currentUser, { displayName });
      }
    },
    sendEmailVerification: async () => {
      if (auth.currentUser && auth.currentUser.email) {
        const settings: ActionCodeSettings = {
          url: `${window.location.origin}/login`,
        };
        await sendEmailVerification(auth.currentUser, settings);
      }
    },
    updateDisplayName: async (displayName: string) => {
      if (auth.currentUser) {
        await updateProfile(auth.currentUser, { displayName });
        await auth.currentUser?.reload();

        setUser(usr => ({
          displayName,
          email: usr?.email,
          phoneNumber: usr?.phoneNumber,
          photoURL: usr?.photoURL,
          uid: usr?.uid,
          emailVerified: usr?.emailVerified,
        }));
      }
    },
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
};