import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AdCard, Button, Grid } from '../../../components';
import { useNotification } from '../../../context/notification';
import IAdvert from '../../../models/IAdvert';
import { AdvertServices } from '../../../services';
import { ArrowBackIcon } from '../../../assets/icons';

export default function View() {
  const navigate = useNavigate();
  const params = useParams();
  const { setNotification } = useNotification();
  const [id, setId] = useState<string>('');
  const [advert, setAdvert] = useState<IAdvert | null>(null);

  useEffect(() => {
    if (params.id) {
      setId(params.id);
    }
  }, [params]);

  useEffect(() => {
    const getAdvert = async () => {
      try {
        const res = await AdvertServices.findAdvert(id);

        setAdvert(res);
      } catch (err: any) {
        setNotification({
          message: err.message,
          severity: 'error',
        });
      }
    };

    if (id) {
      getAdvert();
    }
  }, [id, setNotification]);

  const card = (
    advert && (
      <AdCard
        key={id}
        advert={advert}
        deleteAdvert={() => { }}
      />
    )) || null;

  return (
    <Grid
      container
      justifyContent='center'
      gap={2}
    >
      <Grid
        item
        xs={12}
      >
        <Button
          variant="text"
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
        >
          voltar
        </Button>
      </Grid>
      {card}
    </Grid>
  );
}