import { useReducer } from 'react';
import { useAuth } from '../../../context/auth';
import reducer from '../reducer';
import initialState from '../initialState';
import AdvertForm from '../components/form';
import {
  Grid,
  Typography,
} from '@mui/material';

export default function CreateAdvert() {
  const { user } = useAuth();
  const [advert, dispatch] = useReducer(reducer, {
    ...initialState,
    uid: user.uid,
    date: Date.now(),
    advertiser: user.displayName,
  });

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      alignContent="center"
      direction="column"
      sx={{ marginY: 2 }}
    >
      <Typography variant="h6" component="h6" gutterBottom>
        Criar anúncio
      </Typography>
      <AdvertForm state={advert} dispatch={dispatch} />
    </Grid>
  );
}
