import AdvertServices from '../../services/adverts';

const convertToAdvertList = ({ docs }) =>
  docs.map((doc) => ({ id: doc.id, ...doc.data() }));

const convertFileToBlob = (file) => new Blob([file], { type: file.type });

export default class AdvertsActions {
  static async findAllAdverts() {
    try {
      let adverts = await AdvertServices.findAllAdverts();
      adverts = convertToAdvertList(adverts)
        .map(async (advert) => {
          const files = await this.getAdvertFiles(advert.id);

          return { ...advert, files };
        });

      return await Promise.all(adverts);
    } catch (error) {
      console.error(error);
      throw new Error('Ocorreu um erro ao carregar anuncios. Mensagem:', error.message);
    }
  };

  static async findUserAdverts(uid) {
    try {
      let adverts = await AdvertServices.findUserAdverts(uid);
      adverts = convertToAdvertList(adverts)
        .map(async (advert) => {
          const files = await this.getAdvertFiles(advert.id);

          return { ...advert, files };
        });

      return await Promise.all(adverts);
    } catch ({ message }) {
      throw new Error('Ocorreu um erro ao carregar anuncios. Mensagem:', message);
    }
  };

  static async uploadFile(folderName, fileName, file) {
    try {
      const res = await AdvertServices.addAdvert(folderName, fileName, file);

      return res;
    } catch ({ message }) {
      throw new Error('Ocorreu um erro ao carregar anuncios. Mensagem:', message);
    }
  };

  static async updateAdvert(advert, filesToRemove, filesToAdd) {
    try {
      await AdvertServices.updateAdvert(advert);

      let remove, add = [];
      if (filesToRemove) {
        remove = filesToRemove.map(file => this.deleteFile(advert.id, file));
      }

      if (filesToAdd) {
        add = filesToAdd.map(file => {
          const blob = convertFileToBlob(file);
          return AdvertServices.uploadFile(advert.id, file.name, blob);
        });
      }

      return Promise.all([...remove, ...add]);
    } catch ({ message }) {
      throw new Error('Ocorreu um erro ao carregar anuncios. Mensagem:', message);
    }
  };

  static async listAllAdvertFiles(id) {
    try {
      const { items } = await AdvertServices.listAllAdvertFiles(id);

      return items;
    } catch (error) {
      throw new Error('listAllAdvertFiles');
    }
  }

  static async getDownloadURL(path) {
    try {
      const url = await AdvertServices.getDownloadURL(path);

      return url;
    } catch (error) {
      throw new Error('listAllAdvertFiles');
    }
  }

  static async getAdvertFiles(id) {
    try {
      const { items } = await AdvertServices.listAllFiles(id);
      const promises = items.map(async ({ name, fullPath }) => {
        const url = await this.getDownloadURL(fullPath);

        return { name, fullPath, url };
      });

      return await Promise.all(promises);
    } catch (error) {
      throw new Error('listAllAdvertFiles');
    }
  }

  static async updateOrCreateAdvert(state) {
    try {
      let advert = {
        uid: state.uid,
        title: state.title,
        description: state.description,
        categories: state.categories,
        phone: state.phone.replace(/\D/gim, ''),
        price: state.price,
        date: state.date,
        active: state.active,
        acceptExchange: state.acceptExchange,
        state: state.state,
        city: state.city,
        sold: state.sold,
        advertiser: state.advertiser,
      };

      if (state.id) {
        console.log('Salvando edição');
        advert = { ...advert, id: state.id };
        await this.updateAdvert(advert, state.filesToRemove, state.filesToAdd);
      } else {
        console.log('Criando anuncio');
        advert = {
          ...advert,
          date: Date.now(),
        };

        const { id } = await AdvertServices.addAdvert(advert);
        const promises = state.files.map((file) => {
          const blob = convertFileToBlob(file);

          return AdvertServices.uploadFile(id, file.name, blob);
        });

        return await Promise.all(promises);
      }
    } catch (error) {
      throw new Error('updateOrCreateAdvert => ', error.message);
    }
  }

  static async deleteFile(folderName, fileName) {
    try {
      const res = await AdvertServices.deleteFile(folderName, fileName);

      return res;
    } catch ({ message }) {
      throw new Error('Ocorreu um erro ao deletar um arquivo. Mensagem:', message);
    }
  };

  static async deleteAdvert(id) {
    try {
      const promises = [
        AdvertServices.deleteAdvert(id),
        AdvertServices.deleteFolder(id),
      ];

      return Promise.all(promises);
    } catch ({ message }) {
      throw new Error('Ocorreu um erro ao deletar um anúncio. Mensagem:', message);
    }
  }
}
