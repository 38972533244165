import { useState } from 'react';
import { listOfStatus } from '../../utils';
import { ActionCodeSettings } from 'firebase/auth';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useAuth } from '../../context/auth';

const ForgotPassword = () => {
  const { sendPasswordResetEmail } = useAuth();
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(listOfStatus.IDLE);

  const sendEmail = async () => {
    if (email) {
      try {
        setStatus(listOfStatus.PENDING);
        const settings: ActionCodeSettings = {
          url: `${window.location.origin}/login?email=${email}`,
        };
        await sendPasswordResetEmail(email, settings);
        setStatus(listOfStatus.RESOLVED);
      } catch (error) {
        setStatus(listOfStatus.REJECTED);
      }
    };
  }

  return (
    <Paper
      sx={{
        padding: 2,
        marginY: 8,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <Grid item xs={12}>
          <Typography
            variant="h6"
            textAlign="center"
          >
            Redefinição de senha
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="email"
            label="Email"
            autoComplete="false"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            size="small"
            fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="button"
            onClick={sendEmail}
            variant="contained"
            color="primary"
            disabled={status === listOfStatus.PENDING || status === listOfStatus.RESOLVED || !email}
            fullWidth
          >
            {status === listOfStatus.PENDING && 'Enviando...'}
            {status === listOfStatus.RESOLVED && 'Enviado'}
            {status === listOfStatus.IDLE && 'Enviar'}
            {status === listOfStatus.REJECTED && 'Tentar novamente'}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ForgotPassword;
