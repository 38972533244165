import { useReducer, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AdvertForm from '../components/form';
import AdvertService from '../../../services/adverts';
import reducer, { ActionTypes } from '../reducer';
import initialState from '../initialState';
import IAdvert from '../../../models/IAdvert';

import {
  Grid,
  Typography,
} from '@mui/material';

export default function EditAdvert() {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getFiles = async (id: string) => {
      try {
        const files = await AdvertService.listAllFiles(id);

        dispatch({ type: ActionTypes.setFiles, payload: files });
      } catch (error) {
        console.error(error);
      }
    };

    dispatch({ type: ActionTypes.setIsEditing, payload: true });
    const advert = location.state as IAdvert;
    const payload: IAdvert = {
      id: advert.id,
      uid: advert.uid,
      title: advert.title,
      description: advert.description,
      categories: advert.categories,
      phone: advert.phone,
      price: advert.price,
      date: advert.date,
      active: advert.active,
      acceptExchange: advert.acceptExchange,
      city: advert.city,
      state: advert.state,
      sold: advert.sold,
      files: [],
      advertiser: advert.advertiser,
    };
    dispatch({ type: ActionTypes.setAdvert, payload });
    getFiles(advert.id);
  }, [location.state]);

  return (
    <Grid
      container
      item
      xs={12}
      alignItems="center"
      alignContent="center"
      direction="column"
      sx={{ marginY: 2 }}
    >
      <Typography variant="h6" component="h6" gutterBottom>
        Editar anúncio
      </Typography>
      <AdvertForm state={state} dispatch={dispatch} />
    </Grid>
  );
}