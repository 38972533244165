import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  ImageList,
  ImageListItem,
  Button,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  IconButton,
} from '@mui/material';
import { PhotoCamera, Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AdvertActions } from '../../../../actions';
import Paths from '../../../../paths';
import NumberFormat from 'react-number-format';
import IbgeService from '../../../../services/ibge';
import { ActionTypes, IState } from '../../reducer';
import { isAdvertFile } from '../../../../models/IAdvertFile';
import { formatPhone } from '../../../../utils';
import { useNotification } from '../../../../context/notification';
import categories from '../../../../utils/categories';

const titleMaxLength = 50;

function NumberFormatCustom(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      allowNegative={false}
      fixedDecimalScale
      maxLength={10}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

interface IAdvertFormProps {
  state: IState,
  dispatch: any,
}

export default function AdvertForm({ state, dispatch }: IAdvertFormProps) {
  let navigate = useNavigate();
  const { setNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    IbgeService
      .getStates()
      .then(setStates)
      .catch((err) => console.error(err.message));
  }, []);

  useEffect(() => {
    if (state.state) {
      IbgeService
        .getCities(state.state.id)
        .then(setCities)
        .catch(err => console.error(err.message));
    }
  }, [state.state]);

  const save = async () => {
    try {
      setIsLoading(true);

      await AdvertActions.updateOrCreateAdvert(state);
      setNotification({
        message: 'Anúncio salvo com sucesso',
        severity: 'success',
      });
      navigate(Paths.HOME);
    } catch (error) {
      setNotification({
        message: 'Erro ao salvar o anúncio',
        severity: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  /*
    quando está criando os arquivos vão para files,
    os arquivos excluidos vão para filesToRemove

    quando está editando os files são os carregados do firebase
    os arquivos vão para filesToAdd e os excluidos vão para filesToAdd
  */

  const invalidFilesOnCreate = !state.isEditing && (!state.files.length
    || (state.files.length === state.filesToRemove.length && state.filesToAdd.length === 0));

  const invalidFilesOnEdit = state.isEditing
    && (state.files.length === state.filesToRemove.length && state.filesToAdd.length === 0);

  const invalidFiles = invalidFilesOnCreate || invalidFilesOnEdit;

  const saveDisabled = invalidFiles || !(
    state.uid
    && state.title
    && state.description
    && state.categories
    && state.categories.length > 0
    && state.price
    && state.date
    && state.files.length
    && state.state
    && state.city
    && state.phone
    && state.advertiser
  ) || isLoading;

  return (
    <Grid
      container
      direction="row"
      xs={12}
      spacing={3}
      alignItems="center"
      style={{
        maxWidth: 700,
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        {
          state.isEditing
          && (
            <FormControlLabel
              control={
                <Switch
                  checked={state.sold}
                  onChange={({ target }) => dispatch({ type: ActionTypes.setSold, payload: target.checked })}
                  name="sold"
                  color="primary"
                />
              }
              label={state.sold ? 'Vendido' : 'Disponível'}
            />
          )
        }
        <FormControlLabel
          control={
            <Switch
              checked={state.active}
              onChange={({ target }) => dispatch({ type: ActionTypes.setActive, payload: target.checked })}
              name="active"
              color="primary"
            />
          }
          label={state.active ? 'Ativo' : 'Inativo'}
        />
        <FormControlLabel
          control={
            <Switch
              checked={state.acceptExchange}
              onChange={({ target }) => dispatch({ type: ActionTypes.setAcceptExchange, payload: target.checked })}
              name="acceptExchange"
              color="primary"
            />
          }
          label={state.acceptExchange ? 'Aceito troca' : 'Não aceito troca'}
        />
      </Grid>

      {/* TITULO */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="title">Título do anúncio</InputLabel>
          <Input
            id="title"
            type="text"
            value={state.title}
            onChange={({ target }) => dispatch({ type: ActionTypes.setTitle, payload: target.value })}
            inputProps={{ maxLength: titleMaxLength }}
          />
        </FormControl>
      </Grid>

      {/* DESCRIÇÃO */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="description">Descrição</InputLabel>
          <Input
            id="description"
            type="text"
            inputComponent="textarea"
            value={state.description}
            onChange={({ target }) => dispatch({ type: ActionTypes.setDescription, payload: target.value })}
            multiline
            rows={10}
          />
        </FormControl>
      </Grid>

      {/* CATEGORIAS */}
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            value={state.categories}
            onChange={(_e, payload) => {
              dispatch({
                type: ActionTypes.setCategories,
                payload,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Categorias"
              />
            )}
            options={categories}
          />
        </Grid>
      </Grid>

      {/* VALOR */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="price">Valor</InputLabel>
          <Input
            id="price"
            type="text"
            value={state.price}
            onChange={({ target }) => dispatch({ type: ActionTypes.setPrice, payload: target.value })}
            startAdornment={
              <InputAdornment position="start">R$</InputAdornment>
            }
            inputComponent={NumberFormatCustom}
            inputProps={{ maxLength: 7 }}
          />
        </FormControl>
      </Grid>

      {/* ESTADO */}
      <Grid item xs={12}>
        <Autocomplete
          value={state.state}
          disabled={Boolean(!states || !states.length)}
          onChange={(_e, payload) => {
            dispatch({ type: ActionTypes.setState, payload });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Estado"
            />
          )}
          options={states}
        />
      </Grid>

      {/* CIDADE */}
      <Grid item xs={12}>
        <Autocomplete
          value={state.city}
          disabled={Boolean(!state.state || !cities || !cities.length)}
          onChange={(_e, payload) => {
            dispatch({ type: ActionTypes.setCity, payload });
          }}
          renderInput={(params) => <TextField {...params} label="Cidade" />}
          options={cities}
        />
      </Grid>

      {/* ANUNCIANTE */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="advertiser">Nome do anunciante</InputLabel>
          <Input
            id="advertiser"
            type="text"
            value={state.advertiser}
            onChange={({ target }) => dispatch({
              type: ActionTypes.setAdvertiser,
              payload: target.value,
            })}
          />
        </FormControl>
      </Grid>

      {/* CONTATO */}
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel htmlFor="phone">Contato</InputLabel>
          <Input
            id="phone"
            type="text"
            value={formatPhone(state.phone.toString())}
            inputProps={{ maxLength: "15" }}
            onChange={({ target }) => {
              dispatch({ type: ActionTypes.setPhone, payload: formatPhone(target.value) });
            }}
            placeholder="(##) #####-####"
          />
        </FormControl>
      </Grid>

      <Grid
        container
        item
        xs={12}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <ImageList
          sx={{ width: '100%' }}
          cols={2}
        >
          {
            [
              ...state.files.filter((file: any) => !state.filesToRemove.includes(file.name)),
              ...state.filesToAdd,
            ].map((file) => (
              <ImageListItem
                key={file.name}
                sx={{ justifyContent: 'center' }}
              >
                <IconButton
                  component="span"
                  color="default"
                  onClick={() => dispatch({ type: ActionTypes.setFilesToRemove, payload: file.name })}
                  sx={{
                    position: 'absolute',
                    right: 5,
                    top: 5,
                  }}
                >
                  <Close
                    fontSize="large"
                    sx={{ backgroundColor: 'white' }}
                  />
                </IconButton>
                <Grid
                  component="img"
                  src={isAdvertFile(file) ? file.url : URL.createObjectURL(file)}
                  alt={file.name}
                  sx={{ width: '100%' }}
                  loading="lazy"
                />
              </ImageListItem>
            ))
          }
        </ImageList>
        <Grid
          container
          justifyContent="flex-end"
          item
          xs={12}
        >
          <label htmlFor="icon-button-file">
            <Input
              id="icon-button-file"
              inputProps={{
                type: "file",
                multiple: true,
                accept: "image/png, image/jpeg"
              }}
              onChange={({ target }) => {
                const { files } = target as HTMLInputElement;
                const filesArray = Array.from(files ?? []);

                if (state.id) {
                  dispatch({
                    type: ActionTypes.setFilesToAdd,
                    payload: [...state.filesToAdd, ...filesArray],
                  });
                } else {
                  dispatch({
                    type: ActionTypes.setFiles,
                    payload: [...state.files, ...filesArray],
                  });
                }
              }}
              sx={{ display: 'none' }}
            />
            <Button
              variant='contained'
              color="primary"
              aria-label="upload picture"
              component="span"
              endIcon={<PhotoCamera />}
              size="large"
            >
              Selecionar
            </Button>
          </label>
        </Grid>
      </Grid>

      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <Button
          color="primary"
          onClick={() => navigate(-1)}
        >
          Cancelar
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={save}
          loading={isLoading}
          disabled={saveDisabled}
        >
          Salvar
        </LoadingButton>
      </Grid>
    </Grid >
  );
}