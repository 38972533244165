import axios from 'axios';
import State from '../../models/IState';
import City from '../../models/ICity';

const IBGE_URL_BASE = 'https://servicodados.ibge.gov.br/api/v1/localidades';

export default class IbgeService {
  static getStates = async () => {
    const response = await axios.get(`${IBGE_URL_BASE}/estados`);

    return response
      .data
      .map(({ sigla, nome }: { sigla: string, nome: string }) => {
        const state: State = {
          id: sigla,
          label: nome,
        };

        return state;
      });
  };

  static getCities = async (uf: string) => {
    const response = await axios.get(`${IBGE_URL_BASE}/estados/${uf}/municipios`);

    return response
      .data
      .map(({ id, nome }: { id: number, nome: string }) => {
        const city: City = {
          id,
          label: nome,
        };

        return city;
      });
  };
};
