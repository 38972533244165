const categories: string[] = [
  'Adaptador',
  'Alto falante de porta',
  'Alto falante',
  'Bateria',
  'Caixa 4 vias completa',
  'Caixa 4 vias de entrada',
  'Caixa automotiva residencial',
  'Caixa de som vazia',
  'Caixa high performance',
  'Caixa trio completa',
  'Caixa trio de entrada',
  'Central multimídia',
  'Controle de longa distância',
  'Conversor de energia',
  'Corneta',
  'Corneteira',
  'Driver',
  'Fonte',
  'Kit som automotivo',
  'Mesa Crossover',
  'Módulo amplificador',
  'Player',
  'Rádio MP3',
  'Reparo',
  'Reprodutor',
  'Subwoofer',
  'Super tweeter',
  'Tweeter',
  'Woofer',
  'Processador de áudio',
  'Adaptador RCA',
  'Terminal de bateria',
];

export default categories;