// import React, { useState, useEffect } from 'react';
import {
  useRoutes,
  BrowserRouter,
  Outlet,
  Navigate,
  useLocation,
} from 'react-router-dom';
import Paths from './paths';
import {
  Login,
  SiteHome,
  UserHome,
  CreateUser,
  CreateAdvert,
  MyAdverts,
  EditAdvert,
  ForgotPassword,
  ViewAdvert,
} from './pages';
import { useAuth } from './context/auth';
import Layout from './layout';
import NoMatch from './pages/noMatch';
import VerifyEmail from './pages/verifyEmail';

function RequireAuth() {
  let { user } = useAuth();
  let location = useLocation();

  if (!user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!user.emailVerified) {
    return <Navigate to="/verify-email" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

function Routes() {
  let routes = useRoutes([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, element: <SiteHome /> },
        { path: Paths.LOGIN, element: <Login /> },
        { path: Paths.REGISTER, element: <CreateUser /> },
        { path: Paths.FORGOT_PASSWORD, element: <ForgotPassword /> },
        { path: Paths.VERIFY_EMAIL, element: <VerifyEmail /> },
        { path: Paths.VIEW_ADVERT, element: <ViewAdvert /> },
        {
          element: <RequireAuth />,
          children: [
            { path: Paths.USER_HOME, element: <UserHome /> },
            { path: Paths.CREATE_ADVERT, element: <CreateAdvert /> },
            { path: Paths.MY_ADVERTS, element: <MyAdverts /> },
            { path: Paths.EDIT_ADVERT, element: <EditAdvert /> },
          ],
        },
        { path: "*", element: <NoMatch /> },
      ],
    },
  ]);

  return routes;
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};