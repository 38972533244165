const initialState = {
    id: '',
    uid: '',
    title: '',
    description: '',
    categories: [],
    phone: '',
    price: '',
    date: '',
    files: [],
    isEditing: false,
    filesToRemove: [],
    filesToAdd: [],
    active: true,
    acceptExchange: false,
    state: '',
    city: '',
    sold: false,
    advertiser: '',
}

export default initialState;